import React from "react"

import DetailLayout from "../components/detailLayout"
import {Pagination, Icon, WingBlank, WhiteSpace} from 'antd-mobile';
import './article.css'


const data = [
    {
        id: 1,
        title: "社区大脑（含报警数据治理）",
        video: ['https://static.notro.cn/aihome/0826/communitybrain.mp4'],
        cover: ['https://static.notro.cn/aihome/img/communitybrain.png'],
        description: "遴明科技、耀景科技\n" +
            "上海市长宁区北新泾的“AI社区大脑”是响应国家新型智慧城市建设，顺应城市精细化管理框架下，由本土企业遴明科技、耀景科技深入社区基层需求一线精细研制开发的“慧居家园北新泾（AI+HOME）”AI应用场景的智能中枢，在智慧党建引领下，以便民服务为导向，通过”人、事、地、物、组织”实时数据采集汇聚，打通科技孤岛，运用领先的AI技术，深度再造社区服务和治理流程，通过线上线下一体化高效服务，把社区终端功能集成有机整体，实现惠民、善治的目标。\n" +
            "“社区大脑”主要有智能感知、智能决策及智能调度三个模块，可以多维感知、全局洞察、实时决策、持续进化。通过物联感知、人工智能、大数据分析等技术实时洞察社区民生、运营情况，实现北新泾社区智能化协同服务与管理；通过智能分析、研判、预测等能力，感知、挖掘并预测需求，更高效地为社区群众、企业提供精准服务，并加强社区安全，提升社区治理。"

    },
    {
        id: 2,
        title: "身份识别 ",
        video: ['https://static.notro.cn/aihome/0817/auth.mp4'],
        cover: ['https://static.notro.cn/aihome/img/auth.png'],
        description: "为用户提供功能丰富的人员身份认证及核验能力。平台在整个社区系统中承担业务中台的角色，支持居民用户信息的登记、人脸照片的录入功能，赋能社区上层智慧应用，助力各应用精准识别人员身份，为社区居民生活提供便利。统一身份认证平台可以实现大型人脸库的快速查找，支持毫秒级输出搜索结果，支持分布式高并发方案，适配政务外网等多种网络需求。 "
    },
    {
        id: 3,
        title: "无线网络生物探测",
        video: ['https://static.notro.cn/aihome/0826/livingdetect.mp4'],
        cover: ['https://static.notro.cn/aihome/img/livingdetect.png'],
        description: "百芝龙智慧科技推出的无线人体体征感知传感器是将无线信号与AI技术相结合，用于对人体生命体征和活动特征进行探测。传感器能够针对日常生活不便的人群，如独居老人、残障人士或需要长期卧床的病人等，起到24小时不间断看护作用，当出现生命体征异常或突然摔倒无法自救时自动将告警信息发送到终端。同时，本技术又能够有效保障被看护人的隐私，尤其适合安装于卧室、卫生间等场合，实现无缝监护。"
    },
    {
        id: 4,
        title: "24小时智能问诊",
        video: ['https://static.notro.cn/aihome/0817/pingandoctor.mp4'],
        cover: ['https://static.notro.cn/aihome/img/doctor.png'],
        description: "24小时智能问诊室提供以在线问诊为核心的一站式医疗健康平台，并实现365天×24小时全天候运营，集在线问诊、AI诊疗、电子处方及医药智慧零售于一体，其中语音远程问诊功能可让使用者“直面”后台千余名三甲医院专科医生（包含资深主治医生及副高级以上医生团队专家医生团），热门科室全覆盖，由医生开具用药建议，并提供透明、低价的药品采购方式。着重解决社区居民看小病需要长途往返奔波、慢性病患者需要频繁前往医院复诊、身体轻度不适但家中无合适的检测设备获取体征数据、夜间突发疾病患者无资深专科医生紧急给出初步建议等问题。"
    },
    {
        id: 5,
        title: "AI医疗语音助手",
        video: ['https://static.notro.cn/aihome/0826/aimedicalhelper.mp4'],
        cover: ['https://static.notro.cn/aihome/img/aimedicalhelper.png'],
        description: "科大讯飞采用领先的人工智能技术方案，提供语音识别、语音合成、深度学习和自然语言处理，减少医生重复性工作减少病人就医时间。针对长宁区居民慢病外呼管理，减少医护的工作量，做到数据及时同步到社区卫生系统，从而管控社区慢病人群。为了做好社区家庭医生的健康宣教，讯飞提供智能会议系统，通过家庭医生宣教内容结束后生成二维码，居民通过扫描二维码第一时间掌握健康知识。"
    },
    {
        id: 6,
        title: "眼底智能筛查",
        video: ['https://static.notro.cn/aihome/0817/eyecheck.mp4'],
        cover: ['https://static.notro.cn/aihome/img/eye.png'],
        description: "Airdoc人工智能视网膜健康风险评估产品，可以基于视网膜发现早期慢性疾病在视网膜上的异常表现，对于疾病的早发现、早干预、早治疗起到重大促进作用。同时，基于视网膜的检查快速并且无创，可以定期检查视网膜来判断慢性疾病的管理状况。Airdoc与北新泾AI社区合作，在街道布放了Airdoc人工智能视网膜健康风险评估产品，将人工智能健康服务下沉到街道，为周围居民提供了健康风险筛查服务，帮助周边居民可以更好的掌握自己的健康情况，切实响应了健康中国发展策略，是人工智能一大典型应用场景。"
    },
    {
        id: 7,
        title: "智慧健康小屋",
        video: ['https://static.notro.cn/aihome/0817/tizhitest.mp4', 'https://static.notro.cn/aihome/0817/tizhengtest.mp4', 'https://static.notro.cn/aihome/0817/health.mp4'],
        cover: ['https://static.notro.cn/aihome/img/test.png',
            'https://static.notro.cn/aihome/img/tizheng.png',
            'https://static.notro.cn/aihome/img/health.png',],
        description: "通过投放物联网体征感知设备、收集居民的健康体征数据（血糖、血压、脉率、血氧、体温、腰臀比、BMI）、体质监测数据等自动传输至健康云平台，实现基础数据向社区医生（家庭医生）推送、居民测量异常体征向居民个人、居民家属或社区医生发出信息提示等功能，构建“居民预检-异常预警-临床参考-医生管理”的整体流程，有效补充居民电子健康档案、提升高血压和糖尿病患者慢病管理水平、提高健康管理覆盖面。"
    },
    {
        id: 8,
        title: "智能垃圾分类",
        video: ['https://static.notro.cn/aihome/0826/wisdomrubbish.mp4'],
        cover: ['https://static.notro.cn/aihome/img/wisdomrubbish.png'],
        description: "湿垃圾源头处理设备【现场机器】\n" +
            "“白鸥消灭型餐厨垃圾生物降解技术”使用从自然界中分离出的高效安全的菌种，混合成有益菌群培养在载床上，投入处理机。当有餐厨垃圾投入处理机并和菌种搅拌均匀后，菌种会释放出大量的酶，将大分子有机物分解为糖、脂肪酸和氨基酸等短链的低分子有机物。微生物菌以此为养份，代谢出少量的水（每降解一吨垃圾排出200升左右的水）、生物热能和二氧化碳气体。同时微生物菌以几何级数迅速繁殖，可以周而复始地不断“吃”掉新投入的餐厨垃圾。\n" +
            "爱回收两网融合智能分类回收服务【现场机器】\n" +
            "在小区投放智能垃圾回收设备，为居民提供一站式可回收物分类、交投、积分兑换服务。通过回收，实现生活源再生垃圾与其他类别生活垃圾的统一分类交投、分类收运和资源循环利用。"
    },
    {
        id: 9,
        title: "垃圾分类体感游戏",
        video: ['https://static.notro.cn/aihome/0817/rubbish.mp4'],
        cover: ['https://static.notro.cn/aihome/img/rubish.png'],
        description: "“垃圾分类VR科普教育内容”项目通过VR录制视频结合VR互动体验内容的形式打造别具一格，生动有趣的VR科普教育展示内容。通过AI人脸识别，分辨体验者情况，登陆课程后将介绍包含本市日常生活垃圾四分法以及各类生活垃圾的具体分类、分类垃圾后续处理等知识。VR互动体验内容则通过佩戴VR眼镜，观看视频并进行生活垃圾分类操作。游戏设计为多个关卡，根据操作者的得分逐步提升难度。体验结束，后台会记录体验者身份、体验时长、最终得分、正确数、错误数等数据，导出文件后提交至社区大脑。体验内容简单，垃圾种类丰富，适合社区群众、企业白领、学校师生学习体验，通过VR+AI生动的形式教会大家正确分类生活垃圾。"
    },
    {
        id: 10,
        title: "物业服务智能响应",
        description: "整合物业服务线上资源，实现居民足不出户即可通过北新泾街道微信公众号进行在线物业报修，预约使用物业提供的其他居家服务等功能。"
    },
    {
        id: 11,
        title: "智慧出行",
        video: ['https://static.notro.cn/aihome/0826/wisdompark.mp4'],
        cover: ['https://static.notro.cn/aihome/img/wisdompark.png'],
        description: "基于新泾六村小区智能停车管理系统进行数据接入和汇总分析，提供更科学的停车解决方案。"
    },
    {
        id: 12,
        title: "微信智能互动",
        description: "整合各类面向居民提供服务的资源平台，基于北新泾街道微信公众号定制开发智能服务板块，提供物业管理、便民信息查询、活动场馆预约等各类互动功能。"
    },
    {
        id: 13,
        title: "公共服务信息发布",
        description: "在新泾六村精品小区改造基础上，升级建设各楼栋口智能宣传栏，在传统宣传栏上嵌入分众传媒电子屏，为小区居委、业委会、物业等自治主体发布公共服务信息和周边社区商业体发布商业信息提供载体空间，提升宣传发动和居民自治响应能级。"
    },
    {
        id: 14,
        title: "街面自动清扫车",
        video: ['https://static.notro.cn/aihome/0826/aiswipper.mp4'],
        cover: ['https://static.notro.cn/aihome/img/aiswipper.png'],
        description: "熊猫扫路车利用人工智能机器视觉、全场景图像识别，搭配深度学习神经网络，组合惯导等智能驾驶技术，可自主规划路线，自主识别障碍物、行人并主动避让，自动识别红绿灯和制动，实现无水干式清扫、干湿两用作业等，区域遍历清扫，循环闭环清扫，自动跟随或者超车等。可执行行驶区域检测及局部路径优化，改善清扫作业时间限制提高作业效率，减轻道路的压力，改善环卫工人的工作环境，保护其人身安全，降低用工成本。"
    },
    {
        id: 15,
        title: "智能服务机器人",
        video: ['https://static.notro.cn/aihome/0826/servicerobot.mp4'],
        cover: ['https://static.notro.cn/aihome/img/servicerobot.png'],
        description: "Pepper智能服务机器人以近似人形的外表和近似与人类交流的体验，为社区居民带来科技走进生活的新奇使用感受。机器人具备舞蹈、对话、算命、社区介绍等功能，并可通过语音识别、图像识别和人脸识别功能进行远程管理维护和数据收集服务，将为社区带来潜在的改变，包括：提高社区服务所需的宣传教育工作效率和效果，提高政务办公中问题咨询响应的效率，收集社区工作中的交互数据用于服务满意度调查、改善服务效率等，以及作为街区服务者和居民生活中的伙伴，帮助居民逐步从观念和情感上接受和信任人工智能带来的服务效果，从心打造智能服务街区。"
    },
    {
        id: 16,
        title: "城市智慧书房",
        video: ['https://static.notro.cn/aihome/0817/borrowebook.mp4',
            'https://static.notro.cn/aihome/0817/readting.mp4',
            'https://static.notro.cn/aihome/0817/jieshu.mp4',],
        cover: ['https://static.notro.cn/aihome/img/city.png', 'https://static.notro.cn/aihome/img/ebook.png',
            'https://static.notro.cn/aihome/img/read.png',],
        description: "超星“城市智慧书房”通过搭建数字阅读系统、自助借还系统、朗读视听系统、智能学习系统等设备，为读者提供瀑布流扫码借阅电子书、墨水屏阅读本自助借还、24小时智能书柜、电子书借阅机、朗读亭以及少儿智能书桌等产品。通过墨水屏阅读本实现电子阅读自助借阅，24小时智能书柜实现纸书自助借还，瀑布流电子书和歌德电子借阅机实现手机扫码轻松阅读电子书，朗读亭更是给群众提供置身于诵读环境中，少儿智能书桌为少儿提供丰富的少儿资源，触摸式体验。 超星“城市智慧书房”是现有图书馆服务时间和空间的有效补充，读者可以随时、就近的享受阅、借、读、听、视等全方位的服务，显著提高了读者满意度，也使城市文化形象得到提升。"
    },
    {
        id: 17,
        title: "文化云盒",
        description: "北新泾文化云盒作为市民云项目的互联网+基层网点，线上实现与文化云平台的集合，加强社区居民互动体验；线下提供海量文化资源，供居民个人或团体点播观看。其中，线下充分利用现有科技文化数字装备，提升基层服务效能，云盒内有10万分钟优质的文化慕课、艺术课堂、趣味教育、舞台艺术、手工传艺，海派文化、江南文化、红色文化等数字资源，同时也可以上传自己本地的特色文化资源，从而形成更加精准、匹配需要的数字公共服务模块。\n" +
            "线上打造的“云上北新泾”H5站点是具有北新泾特色的数字化服务平台，实现资源的集聚展示和快速传播以及个性化服务，并在线上实现群众的互动参与，最终形成基层服务大数据。此外现场放置的取订票一体机不仅能满足老年人活动预订和活动取票的服务，还能实现线上订票线下验票的闭环；能为管理部门提供有效的数据统计，了解每场活动参与人数，活动参与率等，有效地进行数据统计，监督公共文化服务流程，提高公共文化服务效能。"
    },
    {
        id: 18,
        title: "小区智能步道",
        video: ['https://static.notro.cn/aihome/0826/wisdomwalkway.mp4'],
        cover: ['https://static.notro.cn/aihome/img/wisdomwalkway.png'],
        description: "智慧步道利用城市公共绿道、社区活动空间为载体，在打通居民医疗数据和健身数据的基础上，采用人工智能技术，经物联网汇聚成包含多参数多用户的大数据平台，结合现代医学、AI技术，为全民健康提供私人定制式的运动指导。"
    },
    {
        id: 19,
        title: "体魔方",
        video: ['https://static.notro.cn/aihome/0817/bodymofang.mp4'],
        cover: ['https://static.notro.cn/aihome/img/body.png'],
        description: "体魔方项目由上海市体科所和微创公司联合研发，基于大数据和人工智能技术，在打通区域医疗数据、体质监测数据的前提下，汇集体质健康专家经验，为用户提供有针对性的“运动处方”，加载于社区居民活动中心健身器材之上，指导社区居民特别是慢性病人群科学健身。项目依托现有市民体质健康数据和智能物联设备自动采集的数据，通过后台算法引擎处理，为市民提供便捷服务。同时将围绕用户健康档案展开，形成包括用户基本信息、用户每日锻炼数据、用户医疗健康信息、每日健身互动跟踪数据等个性化健身档案。"
    },
    {
        id: 20,
        title: "无人商业",
        video: ['https://static.notro.cn/aihome/0817/sellgui.mp4',
            'https://static.notro.cn/aihome/0817/sellrobot.mp4'],
        cover: ['https://static.notro.cn/aihome/img/sell.png',
            'https://static.notro.cn/aihome/img/robot.png'],
        description: "兜售机器人：【机器照片】\n" +
            "兜售机器人在室内低速无人驾驶，实现自主定位、导航，自动避障，智能路径规划实现精确环境感知和安全防护；无人售卖功能体现为自主行走，能分辨潜在客户位置，扫手开门，识别所购商品，关门自动结算，真正实现无人值守，解放人力，节省成本。其语音交互功能增强了互动娱乐性，可进行多媒体广告播放，为品牌宣传和市场营销提供新的渠道，拓展营收渠道。 \n" +
            " \n" +
            "AI自贩柜：【机器照片】\n" +
            "采用卷积神经网络、机器视觉、深度学习算法，AI摄像头对商品精确识别、快速计算，算法模型承载容量大，商品品类可根据居民需求调整。AI自贩柜可作为一个可移动的小型便利店，可以实现用传统便利店1/4的成本实现70%的利润。让商品无限接近客群，为居民提供商店就在身边的便捷购物体验。\n" +
            " \n" +
            "现磨咖啡自贩机：【机器照片】\n" +
            "氦豚\n" +
            "人工智能现制饮品机可选支持咖啡、茶等饮品现制现售，满足社区里24小时无休的服务需求，产品制作方便快捷、食材新鲜，具备自主清洁功能，支持个性化饮品定制。\n" +
            " \n" +
            "智能穿搭机器【机器照片/使用界面】\n" +
            "时元\n" +
            "本项目基于3D深度感知技术、Ai智能计算和云计算平台，与鞋服购买相结合，为消费者提供快速便捷的个性化购物服务。通过3D扫描人体和足部，将消费者的身体数据和鞋服商品数据链接到一起；基于消费者身体三维尺寸和个性化购物喜好，在数据大屏上智能快速筛选合适的尺码、服饰搭配和商品，以组合的最优品推送到消费者面前，完成针对消费者的个性化服务。消费者可快速实现线上购买，在实体店缺货下也可快速准确地购买合适的商品；同时，降低电商的退货率、降低实体店的服务成本，极大提高鞋服零售的效率和购物体验。"
    },
    {
        id: 21,
        title: "智慧菜场",
        video: ['https://static.notro.cn/aihome/0826/wisdommarket.mp4'],
        cover: ['https://static.notro.cn/aihome/img/wisdommarket.png'],
        description: "对传统社区菜场进行智能改造，通过客流监控、智能导购、智能客服、价格管理、货架管理等应用，在所采集数据基础上，实现客户行为分析与智能化商户服务等功能。"
    },
    {
        id: 22,
        title: "金融服务体验",
        video: ['https://static.notro.cn/aihome/0817/aibank.mp4'],
        cover: ['https://static.notro.cn/aihome/img/bank.png'],
        description: "依托社区银行资源，共享中国建设银行上海分行“AI+银行”建设成果，面向社区居民提供生活缴费、购物支付、资金支取等一站式综合化金融服务体验，针对老年人等重点人群提供智能、便利、高效、安全的服务体验。"
    },
    {
        id: 23,
        title: "智能政务咨询预约",
        video: ['https://static.notro.cn/aihome/0826/aigaffairs.mp4'],
        cover: ['https://static.notro.cn/aihome/img/aigaffairs.png'],
        description: "整合提升全市“一网通办”系统、区政务咨询预约系统和街道事务受理平台使用效能，在居民区活动中心提供面向社区居民办事需求的智能化预受理、咨询服务，以及相应的智能化预约、投诉与反馈交互服务。"
    },
    {
        id: 24,
        title: "数字电视智能AI政务服务",
        description: "长宁区科委与东方有线等数字电视运营商联合打造面向智慧社区的家庭智能应用，通过高清4K智能机顶盒，结合AI应用，为社区居民提供全方位政务服务和生活服务应用。智能机顶盒不仅作为家庭的视频终端，还成为家庭的智能网关，信息处理终端，AI智能服务终端。利用人工智能+大数据处理技术，让居民足不出户就可以随时使用各类智能化的信息服务。智能语音遥控器的使用不仅解决了中老年人对复杂智能终端的操作复杂性问题，而且通过智能AI语音的应用，让服务“开口说话”，更加亲切。目前服务功能已经涵盖上海市200多个市民日常办理指南的语音互动查询以及与居民生活密切相关的社区周边交通、搬运、家政、托老、家电维修、为老送餐等生活服务。"
    }, {
        id: 25,
        title: "门店招牌检测与警告",
        description: "在街道沿街商铺集中安装门店招牌检测告警设备，通过传感器与智能感知、云计算等技术的集成应用，实施倾斜监测与告警。系统提供安全可靠的监测数据，对店招可能发生的事故进行辅助性的预警，实时展示报警信息，包括报警的店招编号和位置等，在短信提醒商铺所有者的同时，向社区大脑发送告警信息，供社区管理队伍进行有效监督管理。"
    }, {
        id: 26,
        title: "实时监控和感知街面状态",
        video: ['https://static.notro.cn/aihome/0826/streetmonitor.mp4'],
        cover: ['https://static.notro.cn/aihome/img/streetmonitor.png'],
        description: "依托监控探头和图像识别算法，对街面非机动车辆乱停放、跨门营业、乱堆物等城市秩序乱象和人员大量聚集等公共安全预警状况进行重点监控，实时发送告警信息到社区大脑，加速联动反应与应急处置，智能提升社会治理精细化程度。"
    },
    {
        id: 27,
        title: "电梯监控",
        video: ['https://static.notro.cn/aihome/0826/elevatormonitor.mp4'],
        cover: ['https://static.notro.cn/aihome/img/elevatormonitor.png'],
        description: "智慧电梯AI感知：\n" +
            "感知系统通过智能AI摄像头对乘客的异常行为（如摔倒、被困电梯、电瓶车摩托车进入电梯等）识别分析，并对电梯运行状态进行智慧管理。乘客如遇发生电梯被困事故，AI摄像头将后台自动识别乘客异常行为并进行语音播放安抚，帮助被困乘客自动报警。同时，远程救援中心可通过数字电梯多方通话观察被困乘客的活动状态，并对被困乘客进行有效辅助安抚，实现远程救援服务。"
    },
    {
        id: 28,
        title: "电弧火花",
        video: ['https://static.notro.cn/aihome/0826/arcspark.mp4'],
        cover: ['https://static.notro.cn/aihome/img/arcspark.png'],
        description: "东方有线灭弧式电气防火保护装置是基于物联网和智能传感技术的新一代智能化电气防火保护装置。该装置不但可以实时监测电气线路温度、电流、电压等电气安全隐患，还可以在工作电流异常（短路或过载）时切断电源，而且由于其独有灭弧式动作特性，使得短路或过载故障点无危险性火花产生。同时，该装置通过配套的低功耗广域物联网无线通讯模块，可以将线缆温度、电流、电压等参数实时上传至云平台，用户可以通过手机APP端和电脑WEB端实现对电气安全隐患的远程实时监管，有效避免火灾的发生"
    },
    {
        id: 29,
        title: "社区智能安防",
        video: ['https://static.notro.cn/aihome/0826/communityaisafe.mp4'],
        cover: ['https://static.notro.cn/aihome/img/communityaisafe.png'],
        description: "在小区和街面上部署烟感、井盖、地磁等物联传感设备，以及监控探头和人脸识别算法，实时发送安全防范告警信息，提升社区安防和物业管理水平。"
    },
    {
        id: 30,
        title: "5G信号覆盖",
        description: "依托三大运营商资源，在试点区域内实现5G信号的分段覆盖，为AI社区项目搭建技术环境。"
    },
]

class ArticleIndex extends React.Component {

    state = {
        hidePlay: []
    }

    videoPlay = (idx) => {
        this.refs['video' + idx].play();
        const {hidePlay} = this.state;
        hidePlay.push(idx);
        this.setState({hidePlay: hidePlay})
    }

    render() {
        const href = this.props.location.href;
        const id = href ? href.substring(href.indexOf("id=") + 3) : 1;
        const item = data[id - 1];
        console.info("id::", id);
        return (
            <DetailLayout location={this.props.location}>
                <div className='detail article'>
                    <div className="descWrapper">
                        <h1 className='detailTitle'>
                            {/*<a href='/' className='back'><Icon type="left" size='md'/></a>*/}
                            <span className="left"/><span className='item_title'>{item.title}</span><span
                            className="right"/></h1>
                        <p className='description'>{item.description}</p>
                        <p className='description'>{item.description2}</p>
                    </div>

                    <div className="videoList">
                        {item.video && item.video.map((v, idx) => {
                            return <div className="videoItem">
                                <video src={v} type="video/mp4" controls className='video' ref={'video' + idx}
                                poster={item.cover[idx]}>
                                    抱歉，你的浏览器不支持video标签。。
                                </video>
                                {this.state.hidePlay.indexOf(idx) === -1 &&
                                <div className="video-img" onClick={this.videoPlay.bind(this, idx)}></div>}

                            </div>
                        })}
                    </div>
                </div>
            </DetailLayout>
        )
    }
}

export default ArticleIndex
